.form_other {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    justify-content: center;
}

.remembered {
    /* border-bottom: 1px solid #E7E7E7; */
    font-size: 14px;
    color: #5A607F;

}