.root {
    display: grid;
    gap: 62px;
}

.root h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    color: var(--bcn-color-black-001);
    margin-bottom: 8px;
}

.root p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: var(--bcn-color-black-002);
}

.left_bottom img {
    width: 100%;
    max-width: 435px;
}