.root {
    width: 100vw;
    min-height: 100vh;
    overflow-y: auto;
}

:global(.ant-layout-header) {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--bcn-color-blue-003);
    color: var(--bcn-color-white);
}

.header_title {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    color: var(--bcn-color-white);
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.header_right {
    height: 100%;
    display: flex;
    gap: 30px;
    align-items: center;
}

.headerRightBox {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.messageNumBox {
    position: absolute;
    top: -9px;
    right: -9px;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    width: 20px;
    height: 20px;
    text-align: center;
    color: var(--bcn-color-white);
    background-color: var(--bcn-color-red-001);
}

.sider {
    background-color: #108ee9;
}

.logoPanel {
    width: 100%;
    height: 64px;
    margin-bottom: 20px;
}

.logoPanel h1 {
    font-size: 18px;
    color: #fff;
    text-align: center;
}

:global(.ant-layout .ant-layout-sider) {
    background-color: var(--bcn-color-gray-003);
}

:global(.ant-menu-vertical.ant-menu-root) {
    margin: 16px 8px 0 8px;
    background-color: transparent;
    border-inline-end: none !important;
}

:global(.ant-menu-light) {
    background-color: transparent;
    padding: 8px;
}

:global(.ant-menu .ant-menu-submenu-arrow::before),
:global(.ant-menu .ant-menu-submenu-arrow::after) {
    background-color: var(--bcn-color-black-002);
    height: 1px;
}

:global(.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline) {
    background-color: transparent;
}

:global(.ant-menu-vertical >.ant-menu-item) {
    display: flex;
    box-sizing: border-box;
    padding: 7px 10px 7px 16px;
    height: unset;
    line-height: unset;
    border-radius: 3px;
}

:global(.ant-menu-light .ant-menu-item),
:global(.ant-menu-light .ant-menu-submenu)  {
    border-left: 4px solid transparent;
}

:global(.ant-menu-light .ant-menu-item-selected) {
    background-color: var(--bcn-color-white);
    border-left: 4px solid var(--bcn-color-blue-001);
}

:global(.ant-menu .ant-menu-title-content) {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: auto 1fr;
    gap: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: var(--bcn-color-black-002);
    margin-left: 10px;
}

:global(.ant-menu-light .ant-menu-item-selected .ant-menu-title-content) {
    color: var(--bcn-color-blue-001)
}

:global(.ant-menu-light.ant-menu-root.ant-menu-inline) {
    border-inline-end: 0;
}

:global(.ant-layout-content) {
    padding: 16px 24px;
    background: var(--bcn-color-gray-001);
    overflow-y: auto;
    height: calc(100vh - 64px);
    display: grid;
    grid-template-rows: auto 1fr auto;
}

:global(.ant-table-wrapper .ant-table-thead >tr>th) {
    background-color: var(--bcn-color-gray-001);
    border-color: var(--bcn-color-gray-004);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: var(--bcn-color-black-003);
}

:global(.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >thead>tr>th),
:global(.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >tbody>tr>td) {
    border-inline-end: 0;
}

:global(.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >thead>tr>th:last-child),
:global(.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >tbody>tr>td:last-child) {
    border-inline-end: 1px solid var(--bcn-color-gray-004);
}

:global(.ant-table-wrapper .ant-table-tbody >tr >td) {
    border-color: var(--bcn-color-gray-004);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: var(--bcn-color-black-001);
}

.footer {
    padding-top: 20px;
    margin-top: auto;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: var(--bcn-color-black-003);
}