.root {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user_root {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    color: var(--bcn-color-white-001);
}

.iconBox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.name {
    display: flex;
    align-items: center;
    justify-content: center;
}